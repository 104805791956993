<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>
      <el-col :span="8"
        ><el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>View Orders</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <div style="margin: 0px 0" />
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div style="margin: 40px 0"></div>
      </el-col>
    </el-row>
     <!-- Add download button -->
    <el-row>
      <el-col :span="16"></el-col>
      <el-col :span="8">
        <el-button type="primary" float="right" @click="downloadCSV">Download as CSV</el-button>
        <div style="margin: 0px 0" />
      </el-col>
    </el-row>
    <div style="margin:20px;" />
    <el-row>
      <el-col :span="24">
        <el-table
          :data="tableData.data"
          :border="false"
          style="width: 100%"
          v-loading="loading"
          @expand-change="expandChange"
          :row-key="tableKey"
          lazy
        >
          <el-table-column type="expand">
            <template #default="props">
              <el-row :gutter="20" v-loading="props.row.orderDetails == undefined">
                <el-col :span="3"></el-col>
                <el-col :span="6" v-if="props.row.orderDetails != undefined">
                  <div m="4" align="left">
                    <h3>KES {{ props.row.cost.toLocaleString() }}</h3>
                    <p m="t-0 b-2">
                      Status:
                      {{ props.row.orderDetails.order_details.order_status_text }}
                    </p>
                    <p m="t-0 b-2">
                      Distance:
                      {{ props.row.orderDetails.order_details.distance_value }} km
                    </p>
                    <p m="t-0 b-2">
                      Requested by:
                      {{ props.row.orderDetails.customer_details.user_name }}
                    </p>
                    <p
                      m="t-0 b-2"
                      v-if="props.row.orderDetails.delivery_logs.find((log:any) => log.log_type === 'Picked')"
                    >
                      Picked:
                      {{ transformDate(props.row.orderDetails.delivery_logs.find((log:any) => log.log_type === 'Picked' )['log_time'])}}
                    </p>
                    <p
                      m="t-0 b-2"
                      v-if="props.row.orderDetails.delivery_logs.find((log:any) => log.log_type === 'Dropped')"
                    >
                      Delivered:
                      {{ transformDate(props.row.orderDetails.delivery_logs.find((log:any) => log.log_type === 'Dropped')['log_time']) }}
                    </p>
                    <!-- <div style="margin: 50px" /> -->
                  </div>
                </el-col>
                <el-col :span="6">
                  <h3></h3>
                  <p m="t-0 b-2">
                    Pickup:
                    {{
                      props.row.orderDetails === undefined
                        ? ""
                        : props.row.orderDetails.order_details.from_name
                    }}
                  </p>
                  <p m="t-0 b-2">
                    Dropoff:
                    {{
                      props.row.orderDetails === undefined
                        ? ""
                        : props.row.orderDetails.order_details.to_name
                    }}
                  </p>
                  <p m="t-0 b-2">
                    Number of delivery locations:
                    {{
                      props.row.orderDetails === undefined
                        ? 0
                        : props.row.orderDetails.paths.length - 1
                    }}
                  </p>
                  <p></p>
                  <p></p>
                  <p m="t-0 b-2" v-if="props.row.order_status < 4">
                    This order is still ongoing. You can track it
                    <a :href="returnOrderTrackingLink(props.row.order_number)">here</a>
                  </p>
                  <div v-if="props.row.orderDetails !== undefined">
                    <div v-if="hasDeliveryDocuments(props.row.orderDetails.paths)">
                      <vue-easy-lightbox
                        :visible="visibleRef"
                        :imgs="imgsRef"
                        :index="indexRef"
                        @hide="onHide"
                      ></vue-easy-lightbox>
                      <p></p>
                      <el-link
                        type="primary"
                        @click="showMultiple(props.row.orderDetails.paths)"
                      >
                        View Delivery Documents
                      </el-link>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6" v-if="props.row.orderDetails !== undefined">
                  <div align="center">
                    <img
                      :src="getDriverPhotoURL(props.row.orderDetails.driver_details.name)"
                      alt=""
                      class="order-history-driver-image"
                    />
                  </div>
                  <div align="center">
                    <!-- <el-rate
                      v-model="rating"
                      disabled
                      show-score
                      text-color="#ff9900"
                      align="center"
                    />
                    <div style="margin: 20px" /> -->
                    <p m="t-0 b-2">{{ props.row.orderDetails.driver_details.name }}</p>
                    <p m="t-0 b-2">{{ props.row.orderDetails.driver_details.number_plate }}</p> 
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="Transaction" prop="order_number" />
          <el-table-column label="Status">
            <template #default="scope"
              ><span style="text-transform: capitalize">{{
                scope.row.order_status_text
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="User" prop="user_name" /> 
          <el-table-column label="Date">
            <template #default="scope">
              {{ transformDateSQL(scope.row.scheduled_time) }}
            </template>
          </el-table-column>
          <el-table-column label="Amount" align="right">
            <template #default="scope"> {{ scope.row.cost.toLocaleString() }} </template>
          </el-table-column>
          <el-table-column label="From" prop="pickup" show-overflow-tooltip />
          <el-table-column label="To" prop="dropoff" show-overflow-tooltip />
        </el-table>
      </el-col>
      <el-col :span="4"><div /></el-col>
    </el-row>
  </el-main>
</template>

<script lang="ts" setup>
import { computed, ref, reactive } from "vue";
import router from "../../router";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useAuthStore } from "../../store/authStore";
import { DateTime } from "luxon";
import { unparse } from "papaparse";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface User {
  date: string;
  name: string;
  address: string;
  phone: string;
  email: string;
  user_id: number;
}

const loading = ref(false);
const search = ref("");
const tableKey = ref("id");
const rating = ref(5);
const tableData = reactive({ data: <any>[] });

const filterTableData = computed(() =>
  tableData.data.filter(
    (data: any) =>
      !search.value || data.name.toLowerCase().includes(search.value.toLowerCase())
  )
);
const visibleRef = ref(false);
const indexRef = ref(0); // default 0
const imgsRef = ref(<any>[]);

const onShow = () => {
  visibleRef.value = true;
};

const showMultiple = (path: any) => {
  //   imgsRef.value = [
  //     'https://fastly.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY',
  //     {src:'https://fastly.picsum.photos/id/4/5000/3333.jpg?hmac=ghf06FdmgiD0-G4c9DdNM8RnBIN7BO0-ZGEw47khHP4',title: 'this is title'}
  //   ]
  console.log("showMultiple", path);
  imgsRef.value = extractDocumentsInfo(path);
  console.log("images", imgsRef.value);
  indexRef.value = 0; // index of imgList
  onShow();
};
const onHide = () => (visibleRef.value = false);

const postData = async () => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_ORDERS_SERVICE}orders?company_id=${
        store.getUserData.company_id
      }&user_id=${store.getUserData.user_id}&order_status=*&token=${store.getToken}`
    );
    console.log("response data data", response.data.data);
    // Handle the API response here
    loading.value = true;

    if (response.status == 200) {
      response.data.data.forEach((element: any) => {
        // let el = element;
        // el.user_type = element.user_type == 1 ? "Normal" : "Admin";
        tableData.data.push(element);
      });
    } else {
      ElMessage({
        message: "Failed load orders. Kindy refresh the app",
        type: "error",
      });
    }
    loading.value = false;
    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    ElMessage({
      message: "Failed the order history. Kindy refresh the app",
      type: "error",
    });
    console.error("Error:", error);
  }
};
postData();

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};

const transformDate = (date: string) => {
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd HH:mm:ss");
};

const transformDateSQL = (date: string) => {
  return DateTime.fromISO(date, { zone: "UTC" }).toFormat("yyyy-MM-dd HH:mm:ss");
};

const getOrderDetails = async (order: any) => {
  console.log("order", order);

  try {
    const response = await axios.get(
      `${import.meta.env.VITE_ORDERS_SERVICE}orders?order_number=${order}`
    );
    console.log("response coming up");
    console.log(response);
    console.log("--end--");
    if (response.status == 200) {
      if (response.data.status) {
        console.log("ccc", response.data);
        return response.data;
      } else {
        ElMessage({
          message: response.data.message,
          type: "error",
        });
      }
    } else {
      console.log("error in fetching order details");
      ElMessage({
        message: response.data.message,
        type: "error",
      });
      // Handle the API response here
    }
    return {};
  } catch (er) {
    console.log(er);
    return {};
  }
};

const expandChange = (row: any, data: []) => {
  let target = tableData.data.findIndex((element: any) => element.id == row.id);
  console.log("target", target);
  console.log("row", row);
  console.log("data", data);
  if (data.length === 0) {
    console.log("closed row", row);
  } else {
    try {
      getOrderDetails(row.order_number)
        .then((res) => {
          console.log("get order details response");
          console.log(res);
          tableData.data[target]["orderDetails"] = res.data;
        })
        .catch((er) => {
          ElMessage({
            message: "Error getting the order details",
            type: "error",
          });
          console.log(er);
        });
      // tableData.data[target]['vat'] = 35;
    } catch (er) {
      ElMessage({
        message: "Error fetching the order details",
        type: "error",
      });
      console.log(er);
    }
  }
};
const getDriverPhotoURL = (name: string) => {
  return `https://placehold.co/400x400@2x.png?text=${name}`;
};

const returnOrderTrackingLink = (order: string) => {
  return `/track?order=${order}`;
};

const hasDeliveryDocuments = (path: any) => {
  return path.some((obj: any) => obj.hasOwnProperty("document_info"));
};

const extractDocumentsInfo = (data: any) => {
  // Check if 'document_info' key exists in each object
  const result = data.flatMap((item: any) => {
    if (item.document_info) {
      // Extract 'location' and 'document_type' from 'document_info'
      return item.document_info.map((doc: any) => ({
        src: `${import.meta.env.VITE_SPACES_OBJECT_STORAGE}${doc.location}`,
        title: doc.document_type,
      }));
    }
    return [];
  });
  return result;
};
// CSV download functionality
const downloadCSV = () => {
  if (tableData.data.length === 0) {
    ElMessage({
      message: "No data available to download",
      type: "warning",
    });
    return;
  }

  // Prepare the data for CSV
  const csvData = tableData.data.map(row => ({
    Transaction: row.order_number,
    Status: row.order_status_text,
    User: row.user_name,
    Date: transformDateSQL(row.scheduled_time),
    Amount: row.cost,
    From: row.pickup,
    To: row.dropoff,
  }));

  // Convert to CSV format
  const csv = unparse(csvData);

  // Trigger CSV download
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "orders.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
</script>
<style scoped>
.table-search-bar {
  position: fixed;
  margin-top: -5.23rem;
  left: 16rem;
  width: 10rem;
}
.order-history-driver-image {
  width: 100px;
  border-radius: 50%;
}
.vel-modal {
}
</style>
